import {
  handleQueryResolve
} from '../utils';

export default function (pharmName, ocp) {

    const where = [];
    const attrs = [];
    if(pharmName){
      attrs.push({
        param: 'pharmName',
        type: 'sql.VarChar(50)',
        value: '%' + pharmName + '%' // to support like
      });
      where.push('UserName = @pharmName');
    }

    if (ocp) {
      attrs.push({
        param: 'ocp',
        type: 'sql.VarChar(50)',
        value: '%' + ocp + '%' // to support like
      });

      where.push('PharmID LIKE @ocp');
    }

    return this.query(`
    SELECT DISTINCT
    "DBA"."Users"."PharmacistId" as PharmID,
    "DBA"."Users"."FirstName" || ' ' || "DBA"."Users"."LastName" as UserName
    FROM "DBA"."Users"
    WHERE "DBA"."Users"."PharmacistId" != 'NULL'
    `, attrs).then(handleQueryResolve);
  }




